import { AgentAvailability, MessageType } from "./Constant";
import { setMessage } from "../features/app/InteractionSlice";
import { JiraService } from "../services/JiraService";
import Environment from "../Environment";
import { executeDeepLink } from "@microsoft/teams-js";

export const getSuccessMessage = (message) => {
    return { type: MessageType.Success, text: message };
};

export const getErrorMessage = (error) => {
    return { type: MessageType.Error, text: error };
};

export const getWarningMessage = (warning) => {
    return { type: MessageType.Warning, text: warning };
};

export const getInfoMessage = (info) => {
    return { type: MessageType.Info, text: info };
};

export const setLocalStorage = (key, value, ttl = null) => {
    const now = new Date()

    // item is an object which contains the original value
    // as well as the time when it's supposed to expire
    // ttl is in minutes
    const item = {
        value: value,
        expiry: ttl === null ? null : now.getTime() + (ttl * 1000 * 60),
    }

    localStorage.setItem(key, JSON.stringify(item))
};

export const getLocalStorage = (key) => {
    const itemStr = localStorage.getItem(key)

    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (item.expiry !== null && now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage and return null
        localStorage.removeItem(key)
        return null
    }

    return item.value
}

export const removeLocalStorage = (key) => {
    localStorage.removeItem(key)
}

export const generateRandomText = (length) => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

//pass  object array and get comma separated string by specified property 
//objectArray - array
//property - string => "id"
export const getCommaSeparatedString = (objectArray, property = "id") => {
    let idStr = '';
    if (objectArray.length > 0) {
        objectArray.forEach((element, index) => {
            if (objectArray.length === (index + 1)) {
                idStr = idStr.concat(element[property]);
            } else {
                idStr = idStr.concat(`${element[property]},`);
            }
        });
    }
    return idStr;
}

export const validateGUID = (guid) => {
    var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(guid);
}

export const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object
}

export const extractTimeFromSeconds = (duration) => {
    let returnStr = "";
    // Hours, minutes and seconds
    let hrs = ~~(duration / 3600);
    let mins = ~~((duration % 3600) / 60);
    let secs = ~~duration % 60;

    if (hrs > 0) {
        returnStr = `${hrs}:${mins < 10 ? `0${mins}` : mins}:${secs < 10 ? `0${secs}` : secs}`;
    } else {
        returnStr = `${mins < 10 ? `0${mins}` : mins}:${secs < 10 ? `0${secs}` : secs}`;
    }

    return returnStr;
}

export const extractTimeDIfferenceByDate = (date) => {
    let returnStr = "";

    var startDate = new Date(date === null ? new Date() : date);
    var endDate = new Date();

    var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    // Days, Hours, minutes and seconds
    var days = Math.floor(seconds / (3600 * 24));
    var hrs = Math.floor(seconds % (3600 * 24) / 3600);
    var mins = Math.floor(seconds % 3600 / 60);
    // var secs = Math.floor(seconds % 60);

    if (days > 0) {
        returnStr = `${days}d:${hrs}h:${mins}m`;
    } else {
        returnStr = `${hrs < 10 ? `0${hrs}` : hrs}h:${mins < 10 ? `0${mins}` : mins}m`;
    }
    return returnStr;
}

export const checkUserPermission = (userPermissions, validePermissions) => {
    let access = false;

    userPermissions.forEach(permission => {
        let permissionItem = validePermissions.find(x => x === permission);

        if (permissionItem !== undefined) {
            access = true;
            return;
        }
    });

    return access;
}

export const checkLicense = (assignedLicenses, licenses) => {
    return assignedLicenses.some(license => licenses.includes(license));
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const HandleServerResponse = (result, message, dispatch, warning = false, customError = false) => {
    if (result?.success) {
        if (message !== "") {
            //show success message
            dispatch(setMessage(getSuccessMessage(message)));
        }
        else if (customError) {
            //show specific error messsage when api call success
            dispatch(setMessage(getErrorMessage(result?.data)));
        }
    } else {
        if (result?.message !== null) {
            //show error message or warning mesage
            if (warning) {
                dispatch(setMessage(getWarningMessage(result?.message)));
            } else {
                dispatch(setMessage(getErrorMessage(result?.message)));
            }
        } else {
            //Something went wrong, please try again
            dispatch(setMessage(getWarningMessage("Something went wrong, please try again.")));
        }
    }
}

export const HandleServerResponseSettings = (result, name, type, action, dispatch, showMessage = true, showCustomMessage = false, customMessage = null, warning = false) => {

    ///queuename queue is deleted successfully.

    if (showCustomMessage && (customMessage ?? false)) {
        dispatch(setMessage(getSuccessMessage(customMessage)));
        return;
    }

    if (result?.success && showMessage) {
        let successMessage = `${name} ${type ?? ""} is ${action} successfully.`;
        dispatch(setMessage(getSuccessMessage(successMessage)));
        return;
    }

    if (!result?.success && (result?.message ?? false)) {
        dispatch(setMessage(getErrorMessage(result?.message)));
        return;
    }

    if (warning) {
        //Show warning message
        return;
    }

    dispatch(setMessage(getWarningMessage("Something went wrong, please try again.")));
}

export const LogInformation = (title, data) => {
    if (Environment.config.DEBUG) {
        if (data) {
            console.log(title, data);
        } else {
            console.log(title);
        }
    }

};

export const AddTabToLocalStorage = (info, index) => {
    setLocalStorage("cc-tabs-index", index);

    let currentTabs = getLocalStorage("cc-tabs");

    if (currentTabs === null) {
        currentTabs = [info];
    } else {
        currentTabs = currentTabs.map(tab => {
            if (tab.id === info.id && tab.id === "conversations") {
                return {
                    ...tab,
                    data: info.data
                };
            }
            return tab;
        });

        if (!currentTabs.find(tab => tab.id === info.id)) {
            currentTabs = [...currentTabs, info];
        }
    }

    setLocalStorage("cc-tabs", currentTabs);
}

export const RemoveTabFromLocalStorage = (id, index) => {
    setLocalStorage("cc-tabs-index", index);
    let currentTabs = getLocalStorage("cc-tabs");

    if (currentTabs !== null) {
        let filteredList = currentTabs.filter(x => x.id !== id);
        setLocalStorage("cc-tabs", filteredList);
    }
}

export const ClearTabsInLocalStorage = () => {
    removeLocalStorage("cc-tabs");
    removeLocalStorage("cc-tabs-index");
}

export const DecodeJWTToken = (t) => {
    let token = {};
    token.raw = t;
    token.header = JSON.parse(window.atob(t.split('.')[0]));
    token.payload = JSON.parse(window.atob(t.split('.')[1]));
    return (token)
}

//declare global level locale value
var localeValue = null;

const getLocale = () => {
    if (localeValue === null) {
        let locale = getLocalStorage("cc-locale");
        if (locale !== undefined && locale !== null) {
            localeValue = locale
            return locale;
        }
        return 'default';
    }

    return localeValue;
}

export const setLocale = (value) => {
    setLocalStorage("cc-locale", value);
    localeValue = value;
}

export const formatToShortDateTime = (input) => {
    let dtShort = "";

    try {
        if (input) {
            const dt = new Date(input);
            let locale = getLocale();
            dtShort = `${dt.toLocaleString(locale, { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true })}`
        }
    } catch (error) {

    }

    return dtShort;
}

export const formatToLongDateTime = (input) => {
    let dtShort = "";

    try {
        if (input) {
            const dt = new Date(input);
            let locale = getLocale();
            dtShort = `${dt.toLocaleString(locale, { year: "numeric", ignorePunctuation: true, month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true })}`
        }
    } catch (error) {

    }

    return dtShort;
}

export const formatToLongDate = (input) => {
    let dtShort = "";

    try {
        if (input) {
            const dt = new Date(input);
            let locale = getLocale();
            dtShort = dt.toLocaleString(locale, { month: "numeric", day: "numeric", year: 'numeric' });
        }
    } catch (error) {

    }

    return dtShort;
}

export const formatToLongTime = (input) => {
    let dtShort = "";

    try {
        if (input) {
            const dt = new Date(input);
            let locale = getLocale();
            dtShort = dt.toLocaleString(locale, { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })
        }
    } catch (error) {

    }

    return dtShort;
}

export const formatToLongDateTimeChat = (input) => {
    let dtShort = "";

    try {
        if (input) {
            const dt = new Date(input);
            let locale = getLocale();

            if (dt.getDate() !== new Date().getDate()) {
                dtShort = `${dt.toLocaleString(locale, { ignorePunctuation: true, month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true })}`
            } else {
                dtShort = `${dt.toLocaleString(locale, { ignorePunctuation: true, hour: 'numeric', minute: 'numeric', hour12: true })}`
            }
        }
    } catch (error) {

    }

    return dtShort;
}

export const formatToLongDateTimeWithSeconds = (input) => {
    let dtShort = "";

    try {
        if (input) {
            const dt = new Date(input);
            const userLocale =
                navigator.languages && navigator.languages.length
                    ? navigator.languages[0]
                    : navigator.language;
            dtShort = `${dt.toLocaleString(userLocale, { year: "numeric", ignorePunctuation: true, month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })}`
        }
    } catch (error) {

    }

    return dtShort;
}

export const NewGuid = () => {
    function _p8(s) {
        var p = (Math.random().toString(16) + "000000000").substr(2, 8);
        return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
    }
    return _p8() + _p8(true) + _p8(true) + _p8();
}

export const numbersValidation = (value, minValue = 0, maxValue = 0) => {
    if (isNaN(value) === true) {
        return "Should be a number.";
    } else if (value < minValue || value > maxValue) {
        return `Enter a value between ${minValue}-${maxValue}.`;
    } else {
        return "";
    }
}

export const emailValidation = (email) => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        return "";
    }
    return "Enter valid email address";
}

// (123) 456-7890
// (123)456-7890
// 123-456-7890
// 123.456.7890
// 1234567890
// +31636363634
// 075-63546725
export const validatePhoneNumber = (number, isE164 = true) => {
    // + is mandatory for e164 validation
    // else block is used only in transfer to PSTN scenario because country codes with + is provided in a dropdown
    if (isE164) {
        if (/^\+[1-9]\d{4,14}$/im.test(number)) {//minimum digits are 3 because we need to support emergency numbers such as 000
            return "";
        }
    }
    else {
        if (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{2,}[-\s\.]?[0-9]{0,2}$/im.test(number)) {
            return "";
        }
    }
    return "The provided number is invalid. Please ensure it is in the international format.";
}

export const getAltLetters = (person) => {
    let names = person.split(" ");

    let initials = '';

    if (names.length > 1) {
        initials += names[0].charAt(0).toUpperCase();
        initials += names[1].charAt(0).toUpperCase();
    } else {
        initials += names[0].charAt(0).toUpperCase();
        initials += names[0].charAt(1).toUpperCase();
    }

    return initials;
}

export const getColorFromName = (name) => {
    const charCodes = name
        .split('')
        .map(char => char.charCodeAt(0))
        .join('');
    const nameInt = parseInt(charCodes, 10);
    const colors = [
        '#ff66cc',
        '#ff0066',
        '#ff5c33',
        '#ff751a',
        '#ff9933',
        '#47d147',
        '#99cc00',
        '#33ccff',
        '#0099cc',
        '#33adff',
        '#6699ff',
        '#0099cc',
        '#5c85d6',
        '#944dff',
        '#FF00FF',
        '#ff33cc',
        '#cc00cc',
        '#ff6666',
        '#cc9900',
        '#cccc00'
    ];
    return colors[nameInt % colors.length];
}

export const getUserAvailability = (availability, classes) => {
    switch (availability) {
        case AgentAvailability.Available:
        case AgentAvailability.AvailableIdle:
            return classes.available;
        case AgentAvailability.Away:
        case AgentAvailability.BeRightBack:
            return classes.away;
        case AgentAvailability.Busy:
        case AgentAvailability.BusyIdle:
        case AgentAvailability.DoNotDisturb:
            return classes.busy;
        case AgentAvailability.Offline:
        case AgentAvailability.PresenceUnknown:
            return classes.offline;
        default:
            return classes.offline;
    }
}

export const addMonths = (date, addMonth = 0) => {
    var d = date.getDate();
    date.setMonth(date.getMonth() + addMonth);   //getMonth() gives result from 0-11
    if (date.getDate() != d) {
        date.setDate(0);
    }
    return date;
}

export const dateToString = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;    //getMonth() gives result from 0-11
    let year = date.getFullYear();

    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;

    return `${year}-${month}-${day}`;
}

export const isMobileDevice = () => {
    // if (TeamsProvider.isAvailable) {
    //     let deviceType = TeamsProvider.globalProvider?.teamsContext?.hostClientType;
    //     if (deviceType === "android" || deviceType === "ios") {
    //         return true;
    //     }
    // }
    if (/Android|iPhone/i.test(navigator.userAgent)) {
        return true;
    }

    return false;
}

export const convertToRGB = (hexColor, transparancy = 0.8) => {

    if (hexColor.charAt(0) === "#") hexColor = hexColor.substring(1)

    if (hexColor.length !== 6 && Environment.config.DEBUG) {
        LogInformation("Only six-digits hex colors are allowed.", hexColor);
        return hexColor;
    }

    var aRgbHex = hexColor.match(/.{1,2}/g);
    var aRgb = [
        parseInt(aRgbHex[0], 16),
        parseInt(aRgbHex[1], 16),
        parseInt(aRgbHex[2], 16)
    ];
    return `rgb(${aRgb},${transparancy})`;
}

export const isValidHttpUrl = (string) => {
    var url;
    try {
        url = new URL(string);
    } catch (_) {
        return false
    }

    return url.protocol === "https:"
}

export const ValidateJiraToken = (accessToken, expiry) => {
    let tokenValid = false;

    const jwtPayload = JSON.parse(window.atob(accessToken.split('.')[1]))
    const exp = jwtPayload?.exp;

    var currentTimestamp = new Date().getTime() / 1000;
    var tokenIsNotExpired = exp > currentTimestamp;

    tokenValid = tokenIsNotExpired;

    return tokenValid;
}

export const RefreshAccessToken = async (refreshToken, connectorId) => {
    var params = {
        RefreshToken: refreshToken,
        ConnectorId: connectorId,
        JiraAuthGrantTypes: 2
    }

    let result = await JiraService.GetAccessToken(params);

    if (result.success) {
        setLocalStorage('jira-auth', result.data)
        return true;
    }

    return false;
}

export const AuthorizeJira = (AuthUrl) => {
    window.open(AuthUrl, '_blank', 'noopener,noreferrer');
}

export const FormatCallerId = (callerId) => {
    if (callerId.includes("_")) {
        var formattedCallerId = callerId.substr(0, callerId.indexOf('_'));
        if (formattedCallerId.length > 16) {
            var trimmedCallerId = formattedCallerId.substr(0, 15);
            return trimmedCallerId + "...";
        }
        else {
            return formattedCallerId
        }
    }
    else if (callerId.length > 16 && callerId != "Attendant Console") {
        var trimmedCallerId = callerId.substr(0, 15);
        return trimmedCallerId + "...";
    }

    return callerId;
}

export const isValidGUID = (guid) => {
    const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return regex.test(guid);
}

export const handleDeepLink = (url) => {
    try {
        executeDeepLink(url, (status, reason) => {
            if (!status) {
                LogInformation(`Deeplink failed. Reason: ${reason} Url:${url}`);
                window.open(url);
            }
        });
    } catch (ex) {
        window.open(url);
    }
}

export const extractTimeDIfferenceByTwoDates = (startDate, endDate, returnType) => {
    const diffInMilliseconds = endDate.diff(startDate);
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60)  // Convert milliseconds to hours
    const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24)  // Convert milliseconds to days
    const diffInWeeks = diffInMilliseconds / (1000 * 60 * 60 * 24 * 7); // Convert milliseconds to weeks

    if (returnType === "Hours") {
        return diffInHours;
    } else if (returnType === "Days") {
        return diffInDays;
    } else if (returnType === "Weeks") {
        return diffInWeeks;
    } else {
        return 0;
    }

}

export const hasSystemKeywords = (str) => {
    const regex = /\{\$SYSTEM\.(.*?)\}/;
    return regex.test(str);
}

export const replaceKeywords = (template, data) => {
    return template.replace(/\{\$SYSTEM\.(.*?)\}/g, (match, key) => {
        return data[key] || match;
    });
}

export const  isValidDate = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear() > 1970 && !isNaN(date);
}