import React, { useState, useMemo } from 'react'
import Header from './list/Header';
import Item from './list/Item';
import GroupBox from '../../components/GroupBox';
import { useSelector } from 'react-redux';
import { styled } from '@mui/styles';
import { ConversationStatus, ConversationType } from '../../helpers/Constant';

const Container = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 10,
    left: 0,
    right: 0,
    bottom: 50,
    overflowY: 'auto',
    marginTop: 4,
    marginLeft: 7,
    marginRight: 7,
    overflowAnchor: 'none' // This prevents scroll position from changing during updates
}));

const ListItem = styled('div')({
    overflowAnchor: 'auto' // This allows normal scrolling behavior for user interactions
});

const ConversationList = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const { conversations, current } = useSelector((state) => state.conversation);

    const sortConversationsByDate = (conversations) => {
        return conversations.sort((a, b) => new Date(b.created) - new Date(a.created));
    };

    const filterConversations = () => {
        const filteredBySearch = searchQuery
            ? conversations.filter(conversation =>
                conversation.subject?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                conversation.from?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                conversation.to?.toLowerCase().includes(searchQuery.toLowerCase())
            )
            : conversations;

        const emailsWithUserId = filteredBySearch.filter(
            (x) => x.type === ConversationType.Email && x.userId !== null
        );
        const openEmailsWithoutUserId = filteredBySearch.filter(
            (x) => x.type === ConversationType.Email && x.userId === null && x.status === ConversationStatus.Open
        );
        const newEmails = filteredBySearch.filter(
            (x) => x.type === ConversationType.Email && x.status === ConversationStatus.New
        );

        return {
            emailsWithUserId: sortConversationsByDate(emailsWithUserId),
            openEmailsWithoutUserId: sortConversationsByDate(openEmailsWithoutUserId),
            newEmails: sortConversationsByDate(newEmails),
        };
    };

    const renderConversationItems = (filteredConversations) => {
        return filteredConversations.map((conversation) => (
            <ListItem key={conversation.id}>
                <Item data={conversation} selected={current?.id} />
            </ListItem>
        ));
    };

    const filteredConversations = useMemo(() => filterConversations(), [conversations, searchQuery]);

    return (
        <React.Fragment>
            <Header onSearchChange={setSearchQuery} searchQuery={searchQuery} />
            <GroupBox
                smallBodyPadding={true}
                showDivider={false}
                gutter
                sx={{ position: 'relative', height: '92%' }}
            >
                <Container>
                    {conversations.length === 0 && <></>}
                    {renderConversationItems(filteredConversations.emailsWithUserId)}
                    {renderConversationItems(filteredConversations.openEmailsWithoutUserId)}
                    {renderConversationItems(filteredConversations.newEmails)}
                </Container>
            </GroupBox>
        </React.Fragment>
    );
};

export default ConversationList;