import React from "react";
import {
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    FormHelperText,
} from "@mui/material";

export default function DropDown({ label, value, name, onChange, error = null, values, className, size, disable, style, defaultValue = null, noValue = null, required = false, width = "fit-content", id = null, ...other }) {
    let inputWidth = "50%";
    if (size === "large") {
        inputWidth = '100%';
    }
    else if (size === "small-30") {
        inputWidth = '30%';
    }
    else if (size === "small-45") {
        inputWidth = '45%';
    }
    else if (size === "small") {
        inputWidth = '25%'
    } else if (size === "extra-small") {
        inputWidth = '15%'
    }

    return (
        <FormControl
            size="small"
            variant="outlined"
            sx={{ minWidth: inputWidth, width: width }}
            style={{ ...style }}
            {...(error && { error: true })}
        >
            <InputLabel shrink={Boolean(values)} id="demo-simple-select-helper-label">{label}</InputLabel>
            <Select
                disabled={disable || false}
                labelId="demo-simple-select-helper-label"
                id={id}
                name={name}
                value={value}
                label={label}
                onChange={onChange}
                className={className}
                size="small"
                displayEmpty
                {...other}
                notched
            >
                {defaultValue !== null && <MenuItem value={"default"}>{defaultValue}</MenuItem>}
                {noValue !== null && <MenuItem value={"none"}>{noValue}</MenuItem>}
                {
                    values.map((value, index) => (
                        <MenuItem
                            id={`dropdown-id-${value.id}`}
                            key={index}
                            value={value.id}
                        >
                            {value.name}
                        </MenuItem>
                    ))
                }
            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}