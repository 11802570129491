import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import FileUploadButton from "./FileUploadButton";
import { Button, IconButton } from "@mui/material";
import Participants from "./MessageParticipants";
import { ArrowForwardOutlined, DeleteOutlineOutlined } from "@mui/icons-material";
import { ConversationStatus } from "../../../helpers/Constant";
import DisplayFileList from "./DisplayFileList";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import { resetForwardEmail, resetParticipants, setMessageAction, updateContent, updateFiles } from "../ConversationSlice";

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 60,
    padding: '2px 10px 10px 10px',
    width: '100%'
}));

const RightPanel = styled('div')(({ theme }) => ({
    width: '40%',
    marginTop: '10px',
}));

const ButtonGroup = styled('div')(({ theme }) => ({
    alignItems: 'start',
    justifyContent: 'end',
    display: 'flex',
    alignItems: 'center',
    height: '40px'
}));

const DiscardButton = styled(IconButton)(({ theme }) => ({
    marginRight: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
}));

const MessageHeader = ({
    content,
    handleReply,
    isLoading,
    participants,
    errors,
    setErrors
}) => {
    const [openDiscardDialog, setOpenDiscardDialog] = useState(false);

    const { status, userId, messageAction, files } = useSelector(state => state.conversation.current);
    const dispatch = useDispatch();

    return (
        <>
            <Container>
                <Participants
                    messageAction={messageAction}
                    participants={participants}
                    errors={errors}
                    setErrors={setErrors}
                />
                <RightPanel>
                    <ButtonGroup>
                        <DiscardButton onClick={() => setOpenDiscardDialog((prev) => !prev)}>
                            <DeleteOutlineOutlined fontSize='small' />
                        </DiscardButton>
                        <FileUploadButton
                            isLoading={isLoading}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={handleReply}
                            disabled={content === '' || isLoading || !userId ||
                                status === ConversationStatus.Completed ||
                                status === ConversationStatus.Declined}
                            endIcon={<ArrowForwardOutlined />}
                        >
                            {messageAction == 'reply' ? "Reply" : "Forward"}
                        </Button>
                    </ButtonGroup>
                </RightPanel>
            </Container>
            <DisplayFileList selectedFiles={files} />
            {openDiscardDialog &&
                <ConfirmationDialog
                    open={openDiscardDialog}
                    handleClose={async () => setOpenDiscardDialog(false)}
                    confirmChange={async () => {
                        dispatch(setMessageAction(null))
                        dispatch(updateContent(''))
                        dispatch(updateFiles([]))
                        dispatch(resetParticipants())
                        dispatch(resetForwardEmail());
                    }}
                    message={"Are you sure you want to discard this draft ?"}
                />
            }
        </>
    )
}

export default MessageHeader