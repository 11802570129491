import { Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'

const useStyles = makeStyles(theme => ({
    template: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
}));

const TemplateMenu = ({ anchorEl, open, handleClose, setSelectedSignatureOption, templates }) => {
    const classes = useStyles();

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    maxHeight: 80 * 4.5,
                    width: '240px',
                },
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Typography variant='body1' color='primary' style={{ padding: '8px 16px' }}>Global</Typography>
            {templates?.global?.map((option, index) => (
                <Tooltip
                    key={index}
                    title={option.name}
                    placement="right"
                    arrow
                >
                    <MenuItem
                        onClick={() => {
                            setSelectedSignatureOption(option);
                            handleClose();
                        }}
                    >
                        <Typography className={classes.template} variant='body2'>{option.name}</Typography>
                    </MenuItem>
                </Tooltip>
            ))}
            <Typography variant='body1' color='primary' style={{ padding: '8px 16px' }}>User</Typography>
            {templates?.userSignatures?.map((option, index) => (
                <Tooltip
                    key={index}
                    title={option.name}
                    placement="right"
                    arrow
                >
                    <MenuItem
                        key={index}
                        onClick={() => {
                            setSelectedSignatureOption(option);
                            handleClose();
                        }}
                    >
                        <Typography className={classes.template} variant='body2'>{option.name}</Typography>
                    </MenuItem>
                </Tooltip>
            ))}
        </Menu>
    )
}

export default TemplateMenu