import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { Providers } from '@microsoft/mgt';
import {
    USER_SIGNED_IN, USER_SIGNED_OUT, USER_WALLBOARD, USER_OPEN_TAB, USER_CLOSE_TAB,
    addInviteCallTab, removeInviteCallTab, addActiveCallTab, endActiveCallTab, wrapupStart, wrapupEnd,
    changeUserAvailability, handleTabsWhenReconnectSignalR, updateActiveCallTab,
} from "./features/auth/AuthSlice"
import {
    updateCallData, updateUserAvailability, updateQueueStats, updateMultiQueueStats,
    setWaitingCalls, setActiveCalls, setWrapupCalls,
    loadMyStats, updateMyStats,
    setUserAvailability, setQueueStats,
    loadUserAvailability, loadCalls, loadQueueStats, setMessage, setIvrCalls, addIvrCall, removeIvrCall, setCallback,
    setQueueSummaryStats,
} from "./features/app/InteractionSlice";
import { addUserCall, addWaitingCalls, removeUserCall, updateAcCallData, updateUserCall } from "./features/attendent-console/AcSlice";
import { checkLicense, getLocalStorage, getSuccessMessage, LogInformation } from "./helpers/Common"
import { ChatStatus, ConversationAction, ConversationType, LicenseType, Widgets } from "./helpers/Constant";
import Environment from "./Environment";
import { addDashboardConversation, addNewConversation, addNewMessage, assignConversation, loadConversations, loadDashboardConversations, removeConversation, removeDashboardConversation, setUserId, updateCurrentStatus, updateDashboardConversation } from "./features/conversation/ConversationSlice";

let connection = null;
let agentDashboard = false;
let callDashboard = false;
let wallboard = false;
let attendantConsole = false;
let agentStatsWidget = false;
let callStatusWidget = false;
let digitaLicense = false;

const onUserNotification = (data, storeAPI) => {
    if (checkLicense(storeAPI.getState().auth.user.assignedLicenses, [LicenseType.Voice, LicenseType.VoicePlusDigital])) {
        if (data.type === "invitecall") {
            if (!attendantConsole) {
                storeAPI.dispatch(addInviteCallTab(data.data));
            }
        } else if (data.type === "failedcall") {
            storeAPI.dispatch(removeInviteCallTab(data.data));
        } else if (data.type === "activecall") {
            if (!attendantConsole) {
                storeAPI.dispatch(addActiveCallTab(data.data));
            } else {
                storeAPI.dispatch(addUserCall(data.data));
            }
        } else if (data.type === "updatecall") {
            if (!attendantConsole) {
                storeAPI.dispatch(updateActiveCallTab(data.data));
            } else {
                storeAPI.dispatch(updateUserCall(data.data));
            }
        } else if (data.type === "completecall") {
            if (!attendantConsole) {
                storeAPI.dispatch(endActiveCallTab(data.data));
            } else {
                storeAPI.dispatch(removeUserCall(data.data));
            }
        } else if (data.type === "wrapupstart") {
            storeAPI.dispatch(wrapupStart({...data.data, attendantConsole: attendantConsole}));
            if (attendantConsole) {
                storeAPI.dispatch(removeUserCall(data.data));
            }
        } else if (data.type === "wrapupend") {
            storeAPI.dispatch(wrapupEnd(data.data));
        } else if (data.type === "userdashboard") {
            const queues = storeAPI.getState().auth.user.queues.map(function (item) { return item.queueId; });
            storeAPI.dispatch(setUserAvailability({ users: data.data, queues }));
        } else if (data.type === "waitingdashboard") {
            storeAPI.dispatch(setWaitingCalls(data.data));
            if(attendantConsole){
                const queues = storeAPI.getState().auth.user?.queues;
                storeAPI.dispatch(addWaitingCalls({calls: data.data, queues}));   
            }
        } else if (data.type === "calldashboard") {
            storeAPI.dispatch(setActiveCalls(data.data));
        } else if (data.type === "wrapupdashboard") {
            storeAPI.dispatch(setWrapupCalls(data.data));
        } else if (data.type === "queuestats") {
            storeAPI.dispatch(setQueueStats(data.data));
        }
    }

    if (data.type === "mystats") {
        storeAPI.dispatch(updateMyStats(data.data));
    } else if (data.type === "availability") {
        storeAPI.dispatch(changeUserAvailability(data.data));
        if (data.data.forceUpdate) {
            storeAPI.dispatch(setMessage(getSuccessMessage("Supervisor changed your availability")));
        }
    } else if (data.type === "queuesummary") {
        storeAPI.dispatch(setQueueSummaryStats(data.data));
    }

    //Set stats
    if ('stats' in data && data.stats !== null) {
        storeAPI.dispatch(updateMyStats(data.stats));
    }

    LogInformation("onUserNotification", data);
};

const onTenantNotification = (data, storeAPI) => {
    //Wallboard reload request
    if (data.type === "wallboard" && data.data === "reload" && wallboard) {
        storeAPI.dispatch(loadQueueStats());
    }

    //ivr call notifications
    if (data.type === "ivrdashboard") {
        storeAPI.dispatch(setIvrCalls(data.data));
    }

    if (data.type === "ivrstart") {
        storeAPI.dispatch(addIvrCall(data.data));
    }

    if (data.type === "ivrend") {
        storeAPI.dispatch(removeIvrCall(data.data));
    }

    if (data.type === "callback") {
        storeAPI.dispatch(setCallback(data.data));
    }

    LogInformation("onTenantNotification", data);
};

const onCallNotification = (data, storeAPI) => {
    //Do not process if call dashboard or wallboard is not opened
    if (!(callDashboard || attendantConsole || callStatusWidget)) {
        return;
    }

    storeAPI.dispatch(updateCallData(data));

    var queues = storeAPI.getState().auth.user?.queues;
    
    //Process if attendantConsole opened and user opted into the queue
    if (attendantConsole) {
        let newData = {...data, queues}
        storeAPI.dispatch(updateAcCallData(newData));
    }

    //Set queue stats if wallboard is open
    if (wallboard && 'stats' in data && data.stats !== null) {
        storeAPI.dispatch(updateQueueStats({ queueId: data.data.queueId, stats: data.stats }));
    }

    LogInformation("onCallNotification", data);
};

const onAgentNotification = (data, storeAPI) => {
    //Do not process if call dashboard or wallboard is not opened

    if (!agentDashboard && !agentStatsWidget) {
        return;
    }

    if (data.type === "presence") {
        const user = storeAPI.getState().auth.user;
        let userQueueIds = [];
        Object.entries(data.data.queues).forEach(([key]) => {
            userQueueIds.push(key);
        });

        if (userQueueIds.filter(x => user.queues.find(y => y.queueId === x)).length > 0) {
            storeAPI.dispatch(updateUserAvailability(data.data));
        }
    }

    //Set queue stats if wallboard is open
    if (wallboard && 'stats' in data && data.stats !== null) {
        storeAPI.dispatch(updateMultiQueueStats(data.stats));
    }

    LogInformation("onAgentNotification", data);
};

const onConversationUserNotification = (data, storeAPI) => {
    const actionHandlers = {
        [ConversationAction.New]: () => {
            storeAPI.dispatch(addNewConversation(data));
        },
        [ConversationAction.NewMessage]: () => {
            storeAPI.dispatch(addNewMessage(data));
        },
        [ConversationAction.ActiveStart]: () => {
            storeAPI.dispatch(assignConversation(data));
        },
        [ConversationAction.ActiveEnd]: () => {
            storeAPI.dispatch(updateCurrentStatus(data));
        },
        [ConversationAction.TransferTimeout]: () => {
            storeAPI.dispatch(removeConversation(data));
        },
        [ConversationAction.Fallback]: () => {
            storeAPI.dispatch(removeConversation(data));
        }
    };

    const handler = actionHandlers[data.action];
    if (handler) {
        handler();
    }
}

const onConversationGroupNotification = (data, storeAPI) => {
    const actionHandlers = {
        [ConversationAction.New]: () => {
            storeAPI.dispatch(addNewConversation(data));
        },
        [ConversationAction.NewMessage]: () => {
            storeAPI.dispatch(addNewMessage(data));
        },
        [ConversationAction.ActiveStart]: () => {
            storeAPI.dispatch(assignConversation(data));
        },
        [ConversationAction.TransferToQueue]: () => {
            storeAPI.dispatch(removeConversation(data));
        },
        [ConversationAction.Abandoned]: () => {
            storeAPI.dispatch(removeConversation(data));
        }
    };

    const handler = actionHandlers[data.action];
    if (handler) {
        handler();
    }
}

const onConversationDashboardNotification = (data, storeAPI) => {
    const actionHandlers = {
        [ConversationAction.New]: () => {
            storeAPI.dispatch(addDashboardConversation(data));
        },
        [ConversationAction.Abandoned]: () => {
            storeAPI.dispatch(removeDashboardConversation(data));
        },
        [ConversationAction.ActiveStart]: () => {
            storeAPI.dispatch(updateDashboardConversation(data));
        },
        [ConversationAction.ActiveEnd]: () => {
            storeAPI.dispatch(updateDashboardConversation(data));
        },
        [ConversationAction.TransferToQueue]: () => {
            if(data.type === ConversationType.Chat && data.status === ChatStatus.ActiveQueueTransferred){
                storeAPI.dispatch(removeDashboardConversation(data));
            } else {
                storeAPI.dispatch(updateDashboardConversation(data));
            }       
        },
        [ConversationAction.TransferToUser]: () => {
            storeAPI.dispatch(updateDashboardConversation(data));
        },
        [ConversationAction.TransferTimeout]: () => {
            storeAPI.dispatch(removeDashboardConversation(data));
        },
        [ConversationAction.Fallback]: () => {
            storeAPI.dispatch(updateDashboardConversation(data));
        }
    };

    const handler = actionHandlers[data.action];
    if (handler) {
        handler();
    }
}

const joinGroups = (connection, storeAPI) => {
    const user = storeAPI.getState().auth.user;
    let groups = [user.tenantId];

    //Permission check
    var wallboard = user.permissions.includes("wallboard.read");
    var call = user.permissions.includes("call.dashboardread");
    var agent = user.permissions.includes("agent.dashboardread");
    var ivr = user.permissions.includes("call.dashboardivrread");
    var conversationDashboard = user.permissions.includes("conversation.dashboardread");
    attendantConsole = user.permissions.includes("attendantconsole.read");
    digitaLicense = checkLicense(storeAPI.getState().auth.user.assignedLicenses, [LicenseType.AttendantConsolePlusDigital, LicenseType.VoicePlusDigital])

    if (user?.widgets ?? false) {
        agentStatsWidget = user?.widgets.find(x => x.key === Widgets.AgentStatus && x.value === true) !== undefined ? true : false;
        callStatusWidget = user?.widgets.find(x => x.key === Widgets.CallStatus && x.value === true) !== undefined ? true : false;
    }

    if ((wallboard || call || attendantConsole || callStatusWidget) && Array.isArray(user.queues)) {
        user.queues.forEach(queue => groups.push(queue.queueId));
    }

    if (wallboard || agent || agentStatsWidget) {
        groups.push(`${user.tenantId}-userdashboard`);
    }

    if (ivr || callStatusWidget) {
        groups.push(`${user.tenantId}-ivr`);
    }

    if (wallboard) {
        groups.push(`${user.tenantId}-wallboard`);
    }

    if (digitaLicense && conversationDashboard) {
        user.queues.forEach(queue => groups.push(`conversation-dashboard-${queue.queueId}`));
    }

    //Add to groups
    connection.invoke('JoinGroups', groups);
};

const startSignalRConnection = (connection, storeAPI) => connection.start()
    .then(() => {
        //Join to groups
        joinGroups(connection, storeAPI);

        //Load my stats
        storeAPI.dispatch(loadMyStats());

        //Reload agent dashboard
        if (agentDashboard || agentStatsWidget) {
            storeAPI.dispatch(loadUserAvailability());
        }

        //Reload call dashboard
        if ((callDashboard || attendantConsole || callStatusWidget) &&
            checkLicense(storeAPI.getState().auth.user.assignedLicenses, [LicenseType.Voice, LicenseType.VoicePlusDigital, LicenseType.AttendantConsole, LicenseType.AttendantConsolePlusDigital])) {
            storeAPI.dispatch(loadCalls());
        }

        const { user } = storeAPI.getState().auth;
        var conversationPermissions = user.permissions.includes("conversation.chat") || user.permissions.includes("conversation.email");
        if (digitaLicense && conversationPermissions) {
            storeAPI.dispatch(setUserId(user.userId));
            storeAPI.dispatch(loadConversations());
        }

        var conversationDashboardPermissions = user.permissions.includes("conversation.dashboardread")
        if (digitaLicense && conversationDashboardPermissions) {
            storeAPI.dispatch(loadDashboardConversations());
        }
    })
    .catch(err => {
        console.error('SignalR Connection Error: ', err);
        setTimeout(() => startSignalRConnection(connection, storeAPI), 10000);
    });

const SignalRMiddleware = storeAPI => next => async (action) => {
    if (action.type === USER_SIGNED_IN) {
        //Set dashboards flags
        if (action.payload.tabs !== null) {
            action.payload.tabs.forEach(tab => {
                if (tab.id === 'callDashboard') {
                    callDashboard = true;
                } else if (tab.id === 'agentDashboard') {
                    agentDashboard = true;
                }
            });
        }

        //Close connection is exist
        if (connection !== null) {
            LogInformation('initial connection stop');

            connection.stop();
        }

        let hubUri = Environment.config.HUB_URL;

        connection = new HubConnectionBuilder()
            .withUrl(`${hubUri}`, { accessTokenFactory: async () => await Providers.globalProvider.getAccessToken([]) })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();

        connection.on('user', async data => await onUserNotification(data, storeAPI));
        connection.on('notification', async data => await onTenantNotification(data, storeAPI));
        connection.on('call', async data => await onCallNotification(data, storeAPI));
        connection.on('agent', async data => await onAgentNotification(data, storeAPI));

        connection.on('conversation-group', async data => await onConversationGroupNotification(data, storeAPI));
        connection.on('conversation-user', async data => await onConversationUserNotification(data, storeAPI));
        connection.on('conversation-dashboard', async data => await onConversationDashboardNotification(data, storeAPI));

        connection.onreconnected(connectionId => {
            LogInformation('SignalR onreconnected');

            //handle close waiting, active and wrapup call tabs when signalR reconnected
            storeAPI.dispatch(handleTabsWhenReconnectSignalR());

            joinGroups(connection, storeAPI);

            //Reload my stats
            storeAPI.dispatch(loadMyStats());

            //Reload wallboard
            if (wallboard) {
                storeAPI.dispatch(loadQueueStats());
            }

            //Reload agent dashboard
            if (agentDashboard || agentStatsWidget) {
                storeAPI.dispatch(loadUserAvailability());
            }

            //Reload call dashboard
            if (callDashboard || attendantConsole || callStatusWidget) {
                storeAPI.dispatch(loadCalls());
            }
        });

        connection.onclose(error => {
            if (error) {
                const message = `Connection closed due to error "${error}". Try refreshing the page to restart the connection.`;
                console.error(message);
            }
        });

        startSignalRConnection(connection, storeAPI);
    } else if (action.type === USER_SIGNED_OUT) {
        if (connection !== null) {
            connection.stop();
            LogInformation('connection stop');
        }
    } else if (action.type === USER_OPEN_TAB) {
        if (action.payload.id === "agentDashboard") {
            if (!agentDashboard) {
                agentDashboard = true;
                storeAPI.dispatch(loadUserAvailability());
            }
        } else if (action.payload.id === "callDashboard") {
            if (!callDashboard) {
                callDashboard = true;
                storeAPI.dispatch(loadCalls());
            }
        }
    } else if (action.type === USER_CLOSE_TAB) {
        if (action.payload === "agentDashboard" && !wallboard) {
            agentDashboard = false;
        } else if (action.payload === "callDashboard" && !wallboard) {
            callDashboard = false;
        }
    } else if (action.type === USER_WALLBOARD) {
        wallboard = action.payload;

        if (wallboard) {
            storeAPI.dispatch(loadQueueStats());

            if (!agentDashboard) {
                agentDashboard = true;
                storeAPI.dispatch(loadUserAvailability());
            }

            if (!callDashboard) {
                callDashboard = true;
                storeAPI.dispatch(loadCalls());
            }
        } else {
            const tabs = storeAPI.getState().auth.tabs.map(function (item) { return item.id; });
            const agentTabIdx = tabs.indexOf("agentDashboard");
            const callTabIdx = tabs.indexOf("callDashboard");

            if (agentTabIdx === -1) {
                agentDashboard = false;
            }
            if (callTabIdx === -1) {
                callDashboard = false;
            }
        }
    }

    return next(action);
};

export const sendAgentMessage = async (data) => {
    if (connection !== null) {
        await connection.invoke("SendAgentMessage", data);
    }
}

export const resetNotifications = async (data) => {
    if (connection !== null) {
        await connection.invoke("MarkAsRead", data);
    }
}


export default SignalRMiddleware;