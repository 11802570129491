import { createSlice } from "@reduxjs/toolkit";
import { UserService } from './../../services/UserService';
import { CommunicationType, LicenseType, SettingExitPrompt, Widgets } from './../../helpers/Constant'
import { getLocalStorage, setLocalStorage, removeLocalStorage, HandleServerResponse, AddTabToLocalStorage, RemoveTabFromLocalStorage, isMobileDevice, LogInformation, checkLicense } from "../../helpers/Common";
import { Providers } from "@microsoft/mgt";
import { setUserId } from "../attendent-console/AcSlice";
import Environment from "../../Environment";

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        isAuthUser: false,
        error: null,
        wrapup: null,
        registered: true,
        verified: true,
        multiRegion:false,
        wallboard:{
            centrepal: false,
            powerbi:false
        },
        tabIndex: 0,
        tabs: [],
        userData: {},
        subTabIndex: { 'agent': 0, 'call': 0, 'chat': 0, 'settings': 0 },
        emergencyRedirection: false,
        emergencyRedirectionTarget: '',
        settingUpdated: false,
        tabCloseVerification: false,
        nextMove: { tabIndex: -1, settingIndex: -1, wallboard: {centrepal:false, powerbi:false} }
    },
    reducers: {
        loginSuccess: (state, action) => {
            state.user = action.payload.user;
            state.isAuthUser = true;
            state.error = null;
            state.wrapup = null;
            state.tabIndex = action.payload.tabIndex;
            state.tabs = action.payload.tabs;
        },
        logoutSuccess: (state) => {
            state.users = null;
            state.isAuthUser = false;
            state.error = null;
            state.wrapup = null;
            state.wallboard = {
                centrepal: false,
                powerbi: false
            };
            // state.powerBi = false;
            state.tabIndex = 0;
            state.tabs = [];
        },
        changeUserDetails: (state, action) => {
            state.user = action.payload;
        },
        changeUserAvailability: (state, action) => {
            let userObj = { ...state.user }
            userObj.availability = action.payload.availability;

            //TODO fix the issue
            userObj.lastAvailabilityChange = action.payload.lastAvailabilityChange;
            state.user = { ...userObj }
        },
        updateUserWidgets: (state, action) => {
            let userObj = { ...state.user }
            userObj.widgets = action.payload;
            state.user = { ...userObj };
        },
        loginError: (state, action) => {
            state.users = null;
            state.isAuthUser = false;

            state.error = action.payload.message;
            state.registered = action.payload.registered;
            state.verified = action.payload.verified;
            state.multiRegion = action.payload.multiRegion;
        },
        addInviteCallTab: (state, action) => {
            const tab = state.tabs.filter(item => item.id === action.payload.chainId);

            //Check duplicates
            if (tab.length === 0) {
                state.tabs.push(
                    {
                        id: action.payload.chainId,
                        type: CommunicationType.invitecall,
                        name: action.payload.source,
                        close: true,
                        data: action.payload,
                    }
                );
                authSlice.caseReducers.confirmTabChanged(state, state.tabs.length - 1);
                // state.tabIndex = state.tabs.length - 1;
            }
        },
        removeInviteCallTab: (state, action) => {
            const pos = state.tabs.map(function (item) { return item.id; }).indexOf(action.payload.chainId);
            if (pos >= 0 && pos <= state.tabIndex) {
                authSlice.caseReducers.confirmTabChanged(state, state.tabIndex - 1);
                // state.tabIndex = state.tabIndex - 1;
            }

            state.tabs = state.tabs.filter(item => item.id !== action.payload.chainId);
        },
        addActiveCallTab: (state, action) => {
            const callTabIdx = state.tabs.map(function (item) { return item.id; }).indexOf(action.payload.chainId);

            if (callTabIdx === -1) {
                //Add new tab
                state.tabs.push(
                    {
                        id: action.payload.chainId,
                        type: CommunicationType.activecall,
                        name: action.payload.source,
                        close: true,
                        data: action.payload,
                    }
                );

                authSlice.caseReducers.confirmTabChanged(state, state.tabs.length - 1);
                // state.tabIndex = state.tabs.length - 1;
            } else {
                //Update existing tab
                const tab = state.tabs[callTabIdx];
                tab.type = CommunicationType.activecall;
                tab.data = action.payload
                tab.close = true;
                state.tabs[callTabIdx] = tab;
                authSlice.caseReducers.confirmTabChanged(state, callTabIdx);
                // state.tabIndex = callTabIdx;
            }
        },
        addOldCallTab: (state, action) => {
            const callTabIdx = state.tabs.map(function (item) { return item.id; }).indexOf(action.payload.id);

            if (callTabIdx === -1) {
                //Add new tab for old call
                state.tabs.push(
                    {
                        id: action.payload.id,
                        type: CommunicationType.call,
                        name: action.payload.name,
                        close: true,
                        data: action.payload.data,
                    }
                );

                authSlice.caseReducers.confirmTabChanged(state, state.tabs.length - 1);
                // state.tabIndex = state.tabs.length - 1;
            } else {
                //Update existing tab
                const tab = state.tabs[callTabIdx];
                tab.type = CommunicationType.call;
                tab.data = action.payload.data

                state.tabs[callTabIdx] = tab;
                authSlice.caseReducers.confirmTabChanged(state, callTabIdx);
                // state.tabIndex = callTabIdx;
            }
            AddTabToLocalStorage(action.payload, state.tabIndex);
        },
        updateActiveCallTab: (state, action) => {
            const callTabIdx = state.tabs.map(function (item) { return item.id; }).indexOf(action.payload.chainId);
            if (callTabIdx !== -1) {
                const tab = state.tabs[callTabIdx];
                if (tab.type === CommunicationType.activecall) {
                    tab.data.onHold = action.payload.onHold;
                    tab.data.holdMusic = action.payload.holdMusic;
                    state.tabs[callTabIdx] = tab;
                }
            }
        },
        endActiveCallTab: (state, action) => {
            //Update call status to complete
            const callTabIdx = state.tabs.map(function (item) { return item.id; }).indexOf(action.payload.chainId);
            if (callTabIdx !== -1) {
                const tab = state.tabs[callTabIdx];
                tab.type = CommunicationType.completecall;
                tab.data.onHold = false;
                tab.data.holdMusic = false;
                tab.data.end = action.payload.end;
                state.tabs[callTabIdx] = tab;
            }
        },
        wrapupStart: (state, action) => {
            //Update call status to complete
            const callTabIdx = state.tabs.map(function (item) { return item.id; }).indexOf(action.payload.chainId);
            if (callTabIdx !== -1) {
                const tab = state.tabs[callTabIdx];
                tab.type = CommunicationType.completecall;
                tab.data.onHold = false;
                tab.data.holdMusic = false;
                tab.data.end = action.payload.start;
                state.tabs[callTabIdx] = tab;
            } else if (callTabIdx === -1 && !action.payload.attendantConsole){
                state.tabs.push(
                    {
                        id: action.payload.refId,
                        type: CommunicationType.completecall,
                        name: action.payload.source,
                        close: true,
                        data: {refId: action.payload.refId},
                    }
                );
            }

            //Start wrapup
            const time = new Date(action.payload.end).getTime();
            if (time > new Date().getTime()) {
                state.wrapup = { chainId: action.payload.chainId, end: time };
            }
        },
        wrapupEnd: (state, action) => {
            if (action.payload !== undefined) {
                const callTabIdx = state.tabs.map(function (item) { return item.id; }).indexOf(action.payload.chainId);

                if (callTabIdx !== -1) {
                    const tab = state.tabs[callTabIdx];
                    tab.data.wrapupend = action.payload.end;
                    state.tabs[callTabIdx] = tab;
                }
            }

            state.wrapup = null
        },
        wrapupExtend: (state) => {
            if (state.wrapup !== null) {
                state.wrapup.end = state.wrapup.end + 30000;
            }
        },
        setActiveTab: (state, action) => {
            authSlice.caseReducers.confirmTabChanged(state, action.payload);
            setLocalStorage("cc-tabs-index", state.tabIndex);
        },
        openTab: (state, action) => {
            const callTabIdx = state.tabs.map(function (item) { return item.id; }).indexOf(action.payload.id);
            //Check for existing tab
            if (callTabIdx === -1) {
                state.tabs.push(action.payload);
                authSlice.caseReducers.confirmTabChanged(state, state.tabs.length - 1);
            } else if (state.tabIndex !== callTabIdx) {
                authSlice.caseReducers.confirmTabChanged(state, callTabIdx);
            }else if(action.payload.id === "conversations" && action.payload?.data?.id !== state.tabs[callTabIdx]?.data?.id){
                state.tabs[callTabIdx] = action.payload;
            }

            AddTabToLocalStorage(action.payload, state.tabIndex);
        },
        closeTab: (state, action) => {
            const pos = state.tabs.map(function (item) { return item.id; }).indexOf(action.payload);
            if (pos >= 0 && pos <= state.tabIndex) {
                state.tabIndex = state.tabIndex - 1;
            }

            state.tabs = state.tabs.filter(item => item.id !== action.payload);
            RemoveTabFromLocalStorage(action.payload, state.tabIndex)

            delete state.userData[action.payload];
        },

        handleTabsWhenReconnectSignalR: (state, action) => {
            let tabCount = state.tabs.length;

            //filter active and waiting call tabs if exist
            state.tabs = state.tabs.filter(tab => tab.type !== CommunicationType.activecall && tab.type !== CommunicationType.invitecall);

            let removedTabCount = tabCount - state.tabs.length;
            authSlice.caseReducers.confirmTabChanged(state, state.tabIndex - removedTabCount > 0 ? state.tabIndex - removedTabCount : 0);
            // state.tabIndex = state.tabIndex - removedTabCount > 0 ? state.tabIndex - removedTabCount : 0;

            //remove the wrapup data
            state.wrapup = null
        },
        setUserData: (state, action) => {
            state.userData[action.payload.key] = action.payload.value;
        },
        removeUserData: (state, action) => {
            delete state.userData[action.payload.key];
        },
        setSubTabIndex: (state, action) => {
            state.subTabIndex[action.payload.tab] = action.payload.index;
        },
        confirmTabChanged: (state, action) => {
            var currentTab = state.tabs[state.tabIndex];
            if (currentTab.name === "Setting" && SettingExitPrompt.includes(state.subTabIndex.settings) && state.settingUpdated) {
                state.nextMove.tabIndex = action
                state.tabCloseVerification = true;
            }
            else {
                state.tabIndex = action;
            }
        },
        setSettingUpdated: (state, action) => {
            state.settingUpdated = action.payload;
        },
        setNextSettingIndex: (state, action) => {
            state.tabCloseVerification = true;
            state.nextMove.settingIndex = action.payload;
        },
        setNextWallboard: (state, action) => {
            state.tabCloseVerification = true;
            state.nextMove.wallboard = action.payload;
        },
        wallboard: (state, action) => {
            state.wallboard = action.payload
        },
        tabChangedResult: (state, action) => {
            if (action.payload) {
                state.settingUpdated = false;
                if (state.nextMove.tabIndex !== -1) {
                    state.tabIndex = state.nextMove.tabIndex;
                    authSlice.caseReducers.resetNextMove(state, null);
                }
                else if (state.nextMove.settingIndex !== -1) {
                    state.subTabIndex['settings'] = state.nextMove.settingIndex;
                    authSlice.caseReducers.resetNextMove(state, null);
                }
                else if (state.nextMove.wallboard.centrepal || state.nextMove.wallboard.powerbi) {
                    state.wallboard = state.nextMove.wallboard;
                }
            }
            else {
                authSlice.caseReducers.resetNextMove(state, null);
            }
        },
        resetNextMove: (state, action) => {
            state.nextMove = { tabIndex: -1, settingIndex: -1, wallboard: {centrepal:false, powerbi:false} }
            state.tabCloseVerification = false;
        }
    }
});

export const { loginSuccess, logoutSuccess, changeUserDetails, changeUserAvailability, updateUserWidgets, loginError, addInviteCallTab, removeInviteCallTab,
    addActiveCallTab, updateActiveCallTab, endActiveCallTab, wrapupStart, wrapupEnd, wrapupExtend, addOldCallTab,
    setActiveTab, openTab, closeTab, wallboard, handleTabsWhenReconnectSignalR, setUserData, removeUserData,
    setSubTabIndex, tabChangedResult, setSettingUpdated, setNextSettingIndex, setNextWallboard, setNextPowerBi, resetNextMove
} = authSlice.actions;

export const login = (account, access_token, isTeams = false, newProcess = true) => async dispatch => {
    let user = null;
    try {
        const userData = getLocalStorage('cc.user');
        const mobile = isMobileDevice();

        let requestParams = {
            NewAuth: newProcess,
            IsTeams: isTeams
        }

        //Reload if not in local storage or different user
        if (userData === null || account.oid !== userData.userId || account.tid !== userData.tenantId) {
            let data = await UserService.login(requestParams);

            if (!data.success) {
                if (data.registered && data.redirect) {
                    Environment.Load(data.redirect);
                    data = await UserService.login(requestParams);
                }

                if (!data.success) {
                    dispatch(loginError(data));
                    return;
                }
            }

            setLocalStorage('cc.user', { userId: data.data.id, tenantId: data.data.tenantId, name: data?.data?.name, lastLogin: data.data.lastLogin });
            setLocalStorage('access-token', access_token);
            setLocalStorage('P1', data.data.pilot);
            removeLocalStorage("cc.tabs");
            removeLocalStorage("cc-tabs-index");

            user = {
                userId: data.data.id, tenantId: data.data.tenantId, name: data.data.name, userPrincipalName: data.data.userPrincipalName,
                availability: data.data.availability, lastAvailabilityChange: data.data.lastAvailabilityChange, lastLogin: data.data.lastLogin, hasPhoto: data.data.hasPhoto,
                permissions: data.data.permissions || [], queues: data.data.queues || [], widgets: data.data?.widgets,
                integrationData: data.data.integrationData, smsIntegration: data.data.smsIntegration, userAvailabilityList: data.data.userAvailabilityList,
                mobile: mobile, emergencyMode: data.data.emergencyMode, emergencyRedirectionTarget: data.data.emergencyRedirectionTarget, country: data.data.country, chatEnabled: data.data.chatEnabled,
                filters: data.data.filters, assignedLicenses: data.data.assignedLicenses || [], showLicenseLimitAlert: data.data.showLicenseLimitAlert
            };
        } else {
            //Load user data
            let data = await UserService.getLoginUser(requestParams);

            if (!data.success) {
                if (data.registered && data.redirect) {
                    Environment.Load(data.redirect);
                    data = await UserService.login(requestParams);
                }

                if (!data.success) {
                    dispatch(loginError(data));
                    return;
                }
            }

            setLocalStorage('access-token', access_token);
            setLocalStorage('P1', data.data.pilot);

            user = {
                userId: data.data.id, tenantId: data.data.tenantId, name: data.data.name, userPrincipalName: data.data.userPrincipalName,
                availability: data.data.availability, lastAvailabilityChange: data.data.lastAvailabilityChange, lastLogin: data.data.lastLogin, hasPhoto: data.data.hasPhoto,
                permissions: data.data.permissions || [], queues: data.data.queues || [], widgets: data.data?.widgets,
                integrationData: data.data.integrationData, smsIntegration: data.data.smsIntegration, userAvailabilityList: data.data.userAvailabilityList,
                mobile: mobile, emergencyMode: data.data.emergencyMode, emergencyRedirectionTarget: data.data.emergencyRedirectionTarget, country: data.data.country, chatEnabled: data.data.chatEnabled,
                filters: data.data.filters, assignedLicenses: data.data.assignedLicenses || [], showLicenseLimitAlert: data.data.showLicenseLimitAlert
            };
        }

        let tabIndex = 0;
        let tabs = [
            { id: CommunicationType.home, type: CommunicationType.home, name: 'Home', close: false, data: {} },
        ];

        if (user.permissions.includes("attendantconsole.read")) {
            tabs.push({ id: CommunicationType.attendentConsole, type: CommunicationType.attendentConsole, name: 'Attendant Console', close: false, data: {}, disabled: !checkLicense(user.assignedLicenses, [LicenseType.AttendantConsole, LicenseType.AttendantConsolePlusDigital]) })
        }

        //diapad widget permission
        if (!user.permissions.includes("widget.dialpad") && user?.widgets !== null) {
            let widgets = [...user.widgets];
            widgets = widgets.filter(x => x.key !== Widgets.Dialpad);
            user.widgets = widgets;
        }

        if (!checkLicense(user?.assignedLicenses, [LicenseType.Voice, LicenseType.VoicePlusDigital])) {
            let widgets = [...user.widgets];

            widgets = widgets.filter(x => x.key !== Widgets.Dialpad && x.key !== Widgets.CallStatus && x.key !== Widgets.AgentStatus);

            user.widgets = widgets;
        };

        let lastTabIndex = getLocalStorage("cc-tabs-index");
        var savedTabs = removeSavedTabsBasedOnPermission(user.permissions, checkLicense(user.assignedLicenses, [LicenseType.Voice, LicenseType.VoicePlusDigital]));

        if (savedTabs !== null) {
            tabs = tabs.concat(savedTabs);
            tabIndex = lastTabIndex === null ? 0 : lastTabIndex;

            if ((tabs.length - 1) <= tabIndex) {
                tabIndex = tabs.length - 1;
            }
        }

        dispatch(loginSuccess({ user, tabIndex, tabs }));
        dispatch(setUserId(user?.userId));
    } catch (error) {
        dispatch(loginError("Sign in failed. Please reload the page and try again."));
        return;
    }
}

const removeSavedTabsBasedOnPermission = (permissions, voiceLicense) => {
    let savedTabs = getLocalStorage("cc-tabs");
    let stringifyPermissions = JSON.stringify(permissions);
    let indexes = [];

    if (savedTabs === null) {
        return null;
    }

    savedTabs.forEach((element, index) => {
        if (element.id === CommunicationType.agentDashboard) {
            let result = permissions.filter(x => x === "agent.dashboardread").length
            if (result === 0 || voiceLicense === false) indexes.push(index);
        } else if (element.id === CommunicationType.callDashboard) {
            let result = permissions.filter(x => x === "call.dashboardread").length
            if (result === 0 || voiceLicense === false) indexes.push(index);
        } else if (element.id === CommunicationType.callHistory) {
            let result = permissions.filter(x => x === "call.history").length
            if (result === 0 || voiceLicense === false) indexes.push(index);
        } else if (element.id === CommunicationType.report) {
            let result = stringifyPermissions.search("report")
            if (result === -1) indexes.push(index);
        } else if (element.id === CommunicationType.setting) {
            let result = stringifyPermissions.search("write")
            if (result === -1) indexes.push(index);
        } else if (element.id === CommunicationType.conversationHistory) {
            let result = permissions.filter(x => x === "conversation.history").length
            if (result === 0 || voiceLicense === false) indexes.push(index);
        } else if (element.id === CommunicationType.conversations) {
            let result = permissions.filter(x => x === "conversation.email").length
            if (result === 0 || voiceLicense === false) indexes.push(index);
        }
    });

    indexes.forEach(index => {
        delete savedTabs[index];
    });

    return savedTabs.filter(n => n);
}

export const logout = () => async dispatch => {
    const data = await UserService.logout();
    HandleServerResponse(data, "", dispatch);

    if (data.success) {
        removeLocalStorage("cc.user");
        removeLocalStorage("cc.tabs");
        removeLocalStorage("cc-tabs-index");
        removeLocalStorage("access-token");
        removeLocalStorage("jira-auth");
        removeLocalStorage("cc-leaderboard-queues");
        removeLocalStorage("cc-leaderboard-directions");
        removeLocalStorage("P1");
        removeLocalStorage("cc-teams-signin");
        removeLocalStorage("graph_access_token");
        removeLocalStorage("cc-callDashboard-view");
        removeLocalStorage("cc-multi-region");

        dispatch(logoutSuccess());

        const provider = Providers.globalProvider;
        if (provider && provider.logout) {
            LogInformation("Initializing provider logout")
            await provider.logout();
        }
    }
}

export const saveWidgetData = (currentWidget, data) => async (dispatch, getState) => {
    let currentWidgets = [...getState().auth.user?.widgets];

    let index = currentWidgets.findIndex(x => x.key === currentWidget);

    let agentStatusData = { ...currentWidgets[index] }

    agentStatusData.data = data;

    currentWidgets[index] = agentStatusData

    let params = { Widgets: currentWidgets }

    let result = await UserService.updateUserWidgets(params);

    if (result.success) dispatch(updateUserWidgets(currentWidgets));
}

export const USER_SIGNED_IN = 'auth/loginSuccess';

export const USER_SIGNED_OUT = 'auth/logoutSuccess';

export const USER_OPEN_TAB = 'auth/openTab';

export const USER_CLOSE_TAB = 'auth/closeTab';

export const USER_WALLBOARD = 'auth/wallboard';

export const selectUserPermissions = state => state.auth.user.permissions || [];

export const selectUserData = (state, key) => (key && (key in state.auth.userData) ? state.auth.userData[key] : null);

export default authSlice.reducer;