import React from 'react';
import { Dialog, DialogTitle, DialogContent, CircularProgress, Box } from '@mui/material';
import { Button } from '@mui/material';
import { Close, Done, SmartToy } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux"
import makeStyles from '@mui/styles/makeStyles';
import Input from '../../../components/Inputs/Input';
import DropDown from '../../../components/Inputs/DropDown';
import UseForm from '../../../components/form/UseForm';
import { checkUserPermission, HandleServerResponse } from '../../../helpers/Common';
import Form from '../../../components/form/Form';
import { ChatService } from '../../../services/ChatService';
import { removeActiveChat } from '../ChatSlice';
import { endChat } from '../../conversation/ConversationSlice';


const useStyles = makeStyles(theme => ({
    dialog: {
        margin: 16,
        '& .MuiDialog-paperWidthMd': {
            width: 500
        }
    },
    actions: {
        margin: theme.spacing(2),
        justifyContent: 'space-between',
        display: 'flex',
        paddingLeft: '6px',
        paddingRight: '6px'
    },
    summary: {
        width: '48%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    saveClose: {
        width: '38%',
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

const initialValues = {
    outcome: '',
    summary: '',
}

const conversationDashboardPermission = ['conversation.dashboardread']

export default function ChatNote({ chatId, open, handleClose }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);
    const { currentChat } = useSelector((state) => state.conversation);

    const conversationDashboardEnabled = checkUserPermission(user.permissions, conversationDashboardPermission);

    const [disableAddButton, setDisableAddButton] = React.useState(false);

    const [outcomes, setOutcomes] = React.useState([]);
    const [aiEnabled, setAiEnabled] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const {
        values,
        setValues,
        handleInputChanges,
    } = UseForm(initialValues, false);

    React.useEffect(() => {
        let active = true;
        (async () => {
            var result = await ChatService.Outcomes(currentChat?.queueId);

            if (result.success) {
                setOutcomes(result.data);
                setAiEnabled(result.referrenceData["aiEnabled"]);
            }

            if (!active) return;
        })();

        return () => { active = false; }
    }, [])

    const generateSummary = () => {
        (async () => {
            setLoading(true)
            setDisableAddButton(true);

            let result = await ChatService.Summarize(chatId);
            if (result.success) {
                setValues({ ...values, ["summary"]: result.data })
            }
            else {
                HandleServerResponse(result, result.message, dispatch, true);
            }

            setDisableAddButton(false);
            setLoading(false)

        })();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        (async () => {
            setDisableAddButton(true);
            let params = {
                Summary: values.summary,
                Outcome: values.outcome,
            }

            let result = await ChatService.SetChatAction(chatId, params);

            if (result.success) {
                dispatch(endChat({ id: chatId, convDashboardPermission: conversationDashboardEnabled }));
                HandleServerResponse(result, "Chat updated successfully", dispatch);
                setDisableAddButton(false);
                handleClose()
            }

        })();

    }

    return (
        <Form >
            <Dialog className={classes.dialog} maxWidth='md' open={open} aria-labelledby="form-dialog-title">
                <DialogTitle>
                    {"Add outcome"}
                </DialogTitle>
                <DialogContent>
                    <DropDown
                        size="large"
                        label="Outcomes"
                        value={values.outcome}
                        onChange={(e) => handleInputChanges(e)}
                        name="outcome"
                        values={outcomes}
                    />
                    <Input
                        size="large"
                        label="Summary"
                        name={"summary"}
                        multiline={true}
                        multilineRows={5}
                        paddingRight={0}
                        onChange={(e) => handleInputChanges(e)}
                        value={values.summary}
                    />
                </DialogContent>
                <Box className={classes.actions}>
                    <div className={classes.summary}>
                        {aiEnabled &&
                            <Button
                                startIcon={<SmartToy />}
                                onClick={generateSummary}
                                disabled={disableAddButton}
                                variant="contained"
                                color="primary"
                                disableElevation>Generate AI Summary</Button>
                        }
                        {loading && <CircularProgress size={25} />}
                    </div>
                    <div className={classes.saveClose}>
                        <Button onClick={handleSubmit} disabled={disableAddButton} startIcon={<Done />} variant="contained" color="primary" disableElevation>{"Save"}</Button>
                        <Button
                            onClick={handleClose}
                            startIcon={<Close />}
                            variant="contained"
                            color="grey"
                            disableElevation>Cancel</Button>
                    </div>
                </Box>
            </Dialog>
        </Form>
    );
}
