import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import { Grid, Typography, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStorage, HandleServerResponse, LogInformation, RefreshAccessToken, setLocalStorage, ValidateJiraToken } from "../../helpers/Common"
import DropDown from '../../components/Inputs/DropDown';
import AutoCompleteExtended from "../../components/Inputs/AutoCompleteExtended"
import Input from '../../components/Inputs/Input';
import GroupContainer from '../../components/GroupContainer';
import { CallService } from '../../services/CallService';
import { closeTab, removeUserData, selectUserData, setUserData } from '../auth/AuthSlice';
import GroupBox from '../../components/GroupBox';
import JiraIcon from '../../components/customicons/JiraIcon';
import JiraDialog from './Jira/JiraDialog';
import { useTranslation } from 'react-i18next';
import { CommunicationType } from '../../helpers/Constant';
import SplitButton from './components/SplitButton';

const options = ['Save & Close'];

const WrapupNotes = (props) => {
    const theme = useTheme();

    const styles = {
        errorMessage: {
            width: "100%",
            marginTop: theme.spacing(1)
        },
    }

    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [values, setValues] = useState({ note: "", outcomeId: "", forms: {}, dirty: false });
    const [errorMessage, setErrorMessage] = React.useState("");
    const [disableActionBtn, setDisableActionBtn] = React.useState(false);
    const [openJiraDialog, setOpenJiraDialog] = React.useState(false);
    const userData = useSelector(state => selectUserData(state, props.chainId));
    const { user, tabs } = useSelector((state) => state.auth);

    const { t } = useTranslation();

    const handleChange = (event) => {
        event.preventDefault();
        setErrorMessage("");
        setValues(x => ({ ...x, [event.target.name]: event.target.value, dirty: true }));
    }

    const handleAutoComplete = (event) => {
        setErrorMessage("");
        setValues(x => ({ ...x, [event.target.name]: event.target.value?.id ?? "", dirty: true }));
    }

    const handleCustomFields = (event, isDropdown = false) => {
        event.preventDefault();

        setValues(x => {
            let data = { ...x };
            let holdForms = { ...data.forms }
            let selectedForm = { ...holdForms[event.target.name] };

            selectedForm.value = event.target.value;
            if (isDropdown) {
                selectedForm.value = holdForms[event.target.name]?.items?.find(x => x.id === event.target.value)?.name
            }

            holdForms[event.target.name] = selectedForm
            data.forms = holdForms;
            data.dirty = true;
            return data;
        });
    }

    const saveCallNote = (event, saveAndEndWrapup) => {
        event.preventDefault();

        if (values.note === "" && values.outcomeId === "") {
            setErrorMessage(t("Please fill Note or Outcome to save"))
        } else {
            (async () => {
                setDisableActionBtn(true);
                setErrorMessage("");

                let forms = [];
                let parent = [];
                Object.entries(values.forms).map(([k, v]) => {
                    forms.push({ key: k, value: v.value });
                    parent.push({ id: k, name: v.name, value: v.value });
                });

                let note = {
                    ReferenceId: props.referenceId,
                    ChainId: props.chainId,
                    Note: values.note,
                    Outcome: values.outcomeId,
                    Forms: forms.length === 0 ? null : forms,
                    IntegrationId: props?.integrationData?.id ?? null,
                    IntegrationType: props?.integrationData?.integrationType ?? null,
                    IntegrationEntityType: props?.integrationData?.entityType ?? null,
                    ConnectorId: props?.integrationData?.connectorId ?? null,
                    UserId: user.userId,
                    SaveAndEndWrapup: saveAndEndWrapup
                };

                let result = await CallService.SaveCallNote(note);

                LogInformation("CallService.SaveCallNote", result);
                HandleServerResponse(result, t("Note updated"), dispatch);
                setDisableActionBtn(false);

                if (result.success) {
                    props.handlePreviousCallReload();
                    props.updateNoteData({ note: values.note, outcomeId: values.outcomeId, forms: parent });
                    dispatch(removeUserData(props.chainId));

                    if (saveAndEndWrapup) {
                        //close tab
                        let tabId = tabs.some(x => x.id === props.referenceId) ? props.referenceId : props.chainId
                        dispatch(closeTab(tabId))
                    }
                }
            })();
        }
    }

    const handleBlur = () => {
        if (values.dirty) {
            dispatch(setUserData({ key: props.chainId, value: values }));
        }
    }

    const appendRemovedDropdownItems = (dropdownItem, value) => {
        if (dropdownItem.type === 0) {
            return null;
        }

        let items = [...dropdownItem.items];

        let exist = dropdownItem.items.some(x => x === value);
        if (!exist && value !== "") {
            items.push(value);
        }

        return items.map((val, key) => ({ id: key, name: val }))
    }

    useEffect(() => {
        setLoading(true);
        if (props.disabled === false && userData && userData?.dirty === true) {
            setValues(userData);
        } else {
            let data = { note: props.dataObj?.note, outcomeId: props.dataObj?.outcomeId, forms: {}, dirty: false };
            props.customNoteFields && props.customNoteFields.map((item) => {
                let val = "";
                if (props.dataObj?.forms) {
                    const elem = props.dataObj.forms.find(x => x.id == item.id);
                    if (elem) {
                        val = elem.value;
                    }
                }
                data.forms[item.id] = { name: item.name, value: val, items: appendRemovedDropdownItems({ ...item }, val), type: item.type };
            });

            setValues(data);
        }

        setLoading(false)
    }, [props.dataObj, props.customNoteFields])

    const HandleJiraAuth = async (e) => {
        const { JiraConnector } = props;

        if (JiraConnector.authType == 1) {
            const credentials = getLocalStorage('jira-auth')

            if (credentials) {
                if (ValidateJiraToken(credentials.accessToken, credentials.expiry)) {
                    setOpenJiraDialog(true);
                } else {
                    let result = await RefreshAccessToken(credentials.refreshToken, JiraConnector.connectorId)
                    if (result) {
                        setOpenJiraDialog(true);
                    } else {
                        setLocalStorage('jira-connectorId', JiraConnector.connectorId);
                        window.open(JiraConnector.authUrl, '_blank', 'noopener,noreferrer');
                    }
                }
            } else {
                setLocalStorage('jira-connectorId', JiraConnector.connectorId);
                window.open(JiraConnector.authUrl, '_blank', 'noopener,noreferrer');
            }
        } else {
            setOpenJiraDialog(true);
        }
    }

    const JiraButton = () => {
        const { JiraConnector } = props;

        if (JiraConnector) {
            return (
                <IconButton disabled={props.type === CommunicationType.invitecall} size="medium" onClick={(e) => HandleJiraAuth(e)} title={t("Jira Service Management")} aria-label="jira ticket" component="span">
                    <JiraIcon fontSize="small" />
                </IconButton>
            )
        }

        return "";
    }

    const closeJiraModal = () => {
        setOpenJiraDialog(false);
    }

    const findKeyByValue = (items, value) => {
        let item = items.find(x => x.name === value);

        if (item !== undefined) {
            return item.id;
        }

        return null;
    }

    const findObjectById = (items, id) => {
        let item = items.find(x => x.id === id);

        if (item !== undefined) {
            return item;
        }

        return null;
    }

    return (
        <React.Fragment>
            {
                !loading &&
                <GroupBox title={t("Notes")} headerActions={JiraButton()}>
                    <Grid container>
                        <Grid item xs={12}>
                            <GroupContainer>
                                <AutoCompleteExtended
                                    paddingRight={0}
                                    full={true}
                                    multiple={false}
                                    value={findObjectById(props.outcomeValues, values.outcomeId)}
                                    onChange={(e) => handleAutoComplete(e)}
                                    options={props.outcomeValues}
                                    label="Outcome"
                                    name="outcomeId"
                                    onBlur={handleBlur}
                                />
                                {
                                    Object.entries(values.forms).map(([key, item]) => {
                                        if (item.type === 0) {
                                            return (
                                                <Input
                                                    paddingRight={0}
                                                    key={key}
                                                    disable={props.disabled}
                                                    size="large"
                                                    name={key}
                                                    label={item.name}
                                                    onChange={(e) => handleCustomFields(e)}
                                                    value={item.value}
                                                    onBlur={handleBlur}
                                                />
                                            )
                                        }
                                    })
                                }
                                {
                                    Object.entries(values.forms).map(([key, item]) => {
                                        if (item.type === 1) {
                                            return (
                                                <DropDown
                                                    key={key}
                                                    size={"large"}
                                                    disable={props.disabled}
                                                    label={item.name}
                                                    value={findKeyByValue(item.items, item.value)}
                                                    onChange={(e) => handleCustomFields(e, true)}
                                                    name={key}
                                                    values={item.items}
                                                    onBlur={handleBlur}
                                                />
                                            )
                                        }
                                    })
                                }
                                <Input
                                    paddingRight={0}
                                    multiline={true}
                                    disable={props.disabled}
                                    size="large"
                                    name='note'
                                    label={t("Note")}
                                    onChange={handleChange}
                                    value={values.note}
                                    onBlur={handleBlur}
                                />
                                {errorMessage !== "" && <Typography sx={styles.errorMessage} color="error" variant="body2">{errorMessage}</Typography>}
                                <SplitButton
                                    options={options}
                                    saveCallNote={saveCallNote}
                                    disabled={props.disabled || disableActionBtn || !values.dirty}
                                    type={props.type}
                                />
                            </GroupContainer>
                        </Grid>
                    </Grid>
                </GroupBox>
            }
            {
                openJiraDialog &&
                <JiraDialog
                    open={openJiraDialog}
                    closeModal={closeJiraModal}
                    jiraConnector={props.JiraConnector}
                    referenceId={props.current ? props.referenceId : props.dataObj?.referenceId}
                    chainId={props.current ? props.chainId : props.dataObj?.chainId}
                />
            }
        </React.Fragment>
    );
}

export default WrapupNotes;