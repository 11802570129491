import React, { useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Divider, Tabs, Tab, Badge } from '@mui/material';
import { Dashboard, History, ListAlt, Notifications, PhoneCallback, Dialpad as DialpadIcon, NewReleases, PeopleAlt, SettingsPhone, Chat as ChatIcon, MailOutline } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import Summary from './Summary';
import AgentInfo from './AgentInfo';
import TabPanel from '../../components/TabPanel';
import AgentCallHistory from './AgentCallHistory';
import Callback from './Callback';
import ToDos from '../widgets/todos/ToDos';
import Hub from '../widgets/Hub';
import Chat from "../chat/Chat";
import { CallService } from '../../services/CallService';
import Dialpad from '../widgets/Dialpad';
import { ChatStatus, ConversationStatus, ConversationType, LicenseType, Widgets } from '../../helpers/Constant';
import ReleaseNote from '../widgets/release-note/ReleaseNote';
import DoughnutChart from '../../components/chart/DoughnutChart';
import AgentStatus from '../widgets/AgentStatus';
import CallStatus from '../widgets/CallStatus';
import { checkLicense, checkUserPermission } from '../../helpers/Common';
import ConversationList from '../conversation/ConversationList';

const useStyles = makeStyles(theme => ({
    mt: {
        marginTop: 8,
        height: '100%'
    },
}));

export default function ControlPanel(props) {
    const classes = useStyles();
    const theme = useTheme();

    const styles = {
        tab: {
            minWidth: 'unset',
        },
        callbackTab: {
            minWidth: 'unset',
            '& .MuiBadge-root': {
                '& .MuiBadge-anchorOriginTopRightRectangular': {
                    left: theme.spacing(1.5),
                    fontSize: 10
                },
            }
        }
    }

    const [value, setValue] = useState(0);
    const [callbackCount, setCallbackCount] = React.useState(0);
    const [chatNotificationCount, setChatNotificationCount] = React.useState(0);
    const [emailNotificationCount, setEmailNotificationCount] = React.useState(0);
    const { userStats, callback } = useSelector((state) => state.interaction);
    const { queues, permissions, assignedLicenses } = useSelector((state) => state.auth.user);
    const { conversations } = useSelector((state) => state.conversation);

    const chatPermission = checkUserPermission(permissions, ["conversation.chat"])
    const emailPermission = checkUserPermission(permissions, ["conversation.email"])

    const digitalLicense = checkLicense(assignedLicenses, [LicenseType.VoicePlusDigital, LicenseType.AttendantConsolePlusDigital]);

    var dynamicIndexes = 3;

    let dynamicTabs = {
        chat: { index: dynamicIndexes, enable: false },
        conversation: { index: dynamicIndexes + 1, enable: false },
        hub: { index: dynamicIndexes + 2, enable: false }
    }

    if (chatPermission && digitalLicense) {
        dynamicTabs.chat = { index: dynamicIndexes, enable: true }
        dynamicIndexes++
    }

    if (emailPermission && digitalLicense) {
        dynamicTabs.conversation = { index: dynamicIndexes, enable: true }
        dynamicIndexes++
    }

    if (!props.largeScreen) {
        dynamicTabs.hub = { index: dynamicIndexes, enable: true }
        dynamicIndexes++
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        (async () => {
            if (callback) {
                var userQueue = queues.some(x => x.queueId === callback.queueId);
                if (!userQueue) return;
            }
            var { data } = await CallService.CallbackCount();
            setCallbackCount(data);
        })();
    }, [callback, queues])

    React.useEffect(() => {
        setChatNotificationCount((prev) => {
            if (value === 3) {
                return 0;
            }
            let waitingCount = conversations.filter(x => x.type === ConversationType.Chat && x.status === ChatStatus.Waiting).length;
            let activeCount = conversations.filter(x => x.type === ConversationType.Chat && x.status === ChatStatus.Active && x.notifications > 0).length;
            let transferredCount = conversations.filter(x => x.type === ConversationType.Chat && x.status === ChatStatus.ActiveAgentTransferred).length;
            let totalNotifications = waitingCount + activeCount + transferredCount
            return totalNotifications;
        })

        setEmailNotificationCount((prev) => {
            if (value === 4) {
                return 0;
            }
            let newCount = conversations.filter(x => x.type === ConversationType.Email && x.status === ConversationStatus.New).length;
            let openUnredCount = conversations.filter(x => x.type === ConversationType.Email && x.status === ConversationStatus.Open && x.unreadCount > 0).length;
            let totalNotifications = newCount + openUnredCount
            return totalNotifications;
        })

    }, [conversations, value])

    const getIconOrComponent = (name, isIcon = true) => {
        switch (name) {
            case Widgets.ReleaseNote:
                return isIcon ? <NewReleases /> : <ReleaseNote />
            case Widgets.ToDo:
                return isIcon ? <ListAlt /> : <ToDos islong={true} />
            case Widgets.Dialpad:
                return isIcon ? <DialpadIcon /> : <Dialpad />
            case Widgets.AgentStatus:
                return isIcon ? <PeopleAlt /> : <AgentStatus hideTitle={true} />
            case Widgets.CallStatus:
                return isIcon ? <SettingsPhone /> : <CallStatus hideTitle={true} />
            default:
                break;
        }
    }

    return (
        <React.Fragment>
            <Tabs
                variant="fullWidth"
                indicatorColor="primary"
                aria-label="user control panel"
                onChange={handleChange}
                value={value}
                textColor="inherit"
                sx={{ width: '100%' }}
            >
                <Tab id="control-panel-tab-dashboard" title="Dashboard" aria-controls="control-panel-dashboard" sx={styles.tab} icon={<Dashboard />} />
                <Tab id="control-panel-tab-history" title="Call History" aria-controls="control-panel-history" sx={styles.tab} icon={<History />} />
                <Tab
                    id="control-panel-tab-callback"
                    title="Callback"
                    aria-controls="control-panel-callback"
                    sx={styles.callbackTab}
                    component={"div"}
                    label={<Badge badgeContent={callbackCount} color="primary" anchorOrigin={{ vertical: 'top', horizontal: 'right' }}><PhoneCallback /></Badge>}
                />

                {
                    (dynamicTabs.chat.enable && props.largeScreen) &&
                    <Tab
                        id="control-panel-tab-chat"
                        title="Chat"
                        aria-controls="control-panel-chat"
                        sx={styles.tab}
                        label={<Badge badgeContent={chatNotificationCount} color="primary" anchorOrigin={{ vertical: 'top', horizontal: 'right' }}><ChatIcon /></Badge>}
                    />
                }
                {
                    (dynamicTabs.conversation.enable && props.largeScreen) &&
                    <Tab
                        id="control-panel-tab-conversation"
                        title="Conversation"
                        aria-controls="control-panel-chat"
                        sx={styles.tab}
                        label={<Badge badgeContent={emailNotificationCount} color="primary" anchorOrigin={{ vertical: 'top', horizontal: 'right' }}><MailOutline /></Badge>}
                    />
                }
            </Tabs>
            <Divider light />
            <TabPanel id="control-panel-dashboard" aria-labelledby="control-panel-tab-dashboard" value={value} index={0} fill top>
                <DoughnutChart
                    id="agent-sla"
                    labels={["Answered", "Abandoned", "Drops"]}
                    values={
                        [
                            (userStats.activeCalls === 0 && userStats.shortCalls === 0 && userStats.failedCalls === 0) ? 1 : userStats.activeCalls,
                            userStats.failedCalls,
                            userStats.shortCalls,

                        ]
                    }
                    total={userStats.inviteCalls}
                    // showCenter={true}
                    bgColors={
                        [
                            theme.palette.info.main,
                            theme.palette.error.main,
                            theme.palette.grey.darkest,
                        ]
                    }
                />
                <AgentInfo />
                <Summary userStats={userStats} />
            </TabPanel>
            {
                <TabPanel id="control-panel-history" aria-labelledby="control-panel-tab-history" value={value} index={1} fill>
                    <AgentCallHistory />
                </TabPanel>
            }
            {
                <TabPanel id="control-panel-callback" aria-labelledby="control-panel-tab-callback" value={value} index={2} fill>
                    <Callback setCount={setCallbackCount} />
                </TabPanel>
            }
            {
                (dynamicTabs.chat.enable && props.largeScreen) &&
                <TabPanel id="control-panel-chat" aria-labelledby="control-panel-tab-chat" value={value} index={dynamicTabs.chat.index} fill>
                    <Chat />
                </TabPanel>
            }
            {
                (dynamicTabs.conversation.enable && props.largeScreen) &&
                <TabPanel id="control-panel-conversation" aria-labelledby="control-panel-tab-conversation" value={value} index={dynamicTabs.conversation.index} fill>
                    <ConversationList />
                </TabPanel>
            }
        </React.Fragment>
    );
}