import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CommunicationType, ConversationOperation, ConversationStatus, ConversationType } from '../../../helpers/Constant';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Badge, Button, Chip, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import timeago from 'epoch-timeago';
import { setSelectedConversation } from '../ConversationSlice';
import { openTab } from '../../auth/AuthSlice';
import { ConversationService } from '../../../services/ConversationService';
import { HandleServerResponse } from '../../../helpers/Common';
import Priority from '../components/Priority';
import { useTheme } from '@mui/styles';

const Container = styled('div')(({ theme }) => ({
    minHeight: '110px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '8px',
    border: '1px solid #ddd',
    margin: theme.spacing(0, 0, 1, 0),
    borderRadius: '4px',
    backgroundColor: 'transparent',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        cursor: 'pointer'
    },
}));

const Header = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    margin: theme.spacing(1, 0, 1, 0),
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        gap: theme.spacing(0.5),
    }
}));

const HeaderContentRight = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    flex: '1 1 auto',
}));

const HeaderContentLeft = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    flex: '0 0 auto',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        justifyContent: 'flex-end',
    }
}));

const Name = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(1.5),
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    minWidth: 0,
}));

const TimeText = styled(Typography)(({ theme }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    minWidth: 0,
}));

const Subject = styled(Typography)(({ theme }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
}));

const Intent = styled(Typography)(({ theme }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    fontStyle: 'italic'
}));

const AssignButton = styled(Button)(({ theme }) => ({
    height: '24px',
    minWidth: 'auto',
    padding: theme.spacing(0, 1),
}));

const ChipsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
}));

const ChipTag = styled(Chip)(({ theme }) => ({
    height: '22px',
    borderRadius: '8px'
}));

const MailIcon = styled(MailOutlineIcon)(({ theme, status }) => {
    const getColor = () => {
        switch (status) {
            case ConversationStatus.New:
                return theme.palette.error.light;
            case ConversationStatus.Open:
            case ConversationStatus.Completed:
                return theme.palette.success.light;
            default:
                return theme.palette.text.primary;
        }
    };

    return {
        height: '22px',
        borderRadius: '8px',
        color: getColor(),
    };
});

const Item = ({ data }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { user: { userId } } = useSelector((state) => state.auth);
    const { selectedConversation } = useSelector((state) => state.conversation);
    const theme = useTheme();

    const IconComponent = ({ status }) => {
        return (
            <Badge badgeContent={data?.unreadCount} color='primary' anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <MailIcon status={status} fontSize='medium' />
            </Badge>
        )
    };

    const handleSelectedConversation = (conversation) => {
        dispatch(setSelectedConversation(data?.id))
        dispatch(openTab({ id: CommunicationType.conversations, type: CommunicationType.conversations, name: 'Conversations', close: true, data: conversation }))
    };

    const handleAssignAgent = async (event) => {
        event.stopPropagation();
        var values = {
            Id: data?.id,
            ConversationType: ConversationType.Email,
            ConversationOperation: ConversationOperation.Assign
        };
        setIsLoading(true);
        var result = await ConversationService.HandleConversation(values);
        HandleServerResponse(result, "Conversation assigned to you successfully", dispatch, true);
        setIsLoading(false);
    }

    return (
        <Container style={{ backgroundColor: selectedConversation === data?.id ? theme.palette.action.selected : 'transparent', }} onClick={() => handleSelectedConversation(data)}>
            <Header>
                <HeaderContentRight>
                    <IconComponent status={data?.status} />
                    <Name variant="body1">{data?.from}</Name>
                </HeaderContentRight>
                <HeaderContentLeft>
                    <TimeText variant="body1">
                        <time dateTime={new Date(data?.created).toISOString()}>{timeago(new Date(data?.created).getTime())}</time>
                    </TimeText>
                    {(data?.userId !== userId) &&
                        <AssignButton variant='contained' onClick={(event) => handleAssignAgent(event)} disabled={isLoading}>
                            Assign
                        </AssignButton>
                    }
                </HeaderContentLeft>
            </Header>
            <Tooltip title={data?.subject} arrow>
                <Subject variant="body2">{data?.subject}</Subject>
            </Tooltip>
            <Tooltip title={data?.intent} arrow>
                <Intent variant="body2">{data?.intent}</Intent>
            </Tooltip>
            <ChipsContainer>
                <ChipTag label={data?.queue} />
                <Priority priority={data?.priority} />
            </ChipsContainer>
        </Container>
    )
}

export default Item