import axios from 'axios'

const regions = async (id) => {
    const { data } = await axios.get(`/api/authentication/multi-region/${id}`).then(result => result);
    return data;
};

const login = async (values) => {
    const { data } = await axios.post('api/authentication/login', values).then(result => result)
        .catch(error => {
            if (error.response.status === 403) {
                return {
                    data: {
                        success: false,
                        registered: false,
                        message: "In order to use this app, users must have an active account. Please contact your administrator for more information."
                    }
                }
            };
        })
    return data;
};

const logout = async () => {
    const { data } = await axios.post('api/authentication/logout').then(result => result);

    return data;
};

const getLoginUser = async (values) => {
    const { data } = await axios.post('api/authentication/user', values).then(result => result);
    return data;
};

const setAvailability = async (values) => {
    const { data } = await axios.post(`api/authentication/availability`, values).then(result => result);
    return data;
}

const setAvailabilityBySupervisor = async (id, availability) => {
    const { data } = await axios.post(`api/authentication/availability/${id}/${availability}`).then(result => result);
    return data;
}

const getLastAvailabilityChange = async (values) => {
    const { data } = await axios.get('/api/authentication/availability').then(result => result);
    return data;
};

const getGraphAccessToken = async (values) => {
    const { data } = await axios.post(`api/authentication/graph-token`, values).then(result => result);
    return data;
}

const getAll = async (values) => {
    const { data } = await axios.get('/api/users/list', { params: values }).then(result => result);
    return data;
};

const create = async (values) => {
    const { data } = await axios.post('/api/users', values).then(result => result);
    return data;
};

const getAuthorizedQueues = async () => {
    const { data } = await axios.get('/api/users/queues').then(result => result);
    return data;
}

const get = async (id = null) => {
    const { data } = await axios.get(`/api/users/${id}`).then(result => result);
    return data;
}

const update = async (values) => {
    const { data } = await axios.put('/api/users', values).then(result => result);
    return data;
}

const enable = async (id) => {
    const { data } = await axios.post(`/api/users/${id}/enable`).then(result => result);
    return data;
}

const disable = async (id) => {
    const { data } = await axios.post(`/api/users/${id}/disable`).then(result => result);
    return data;
}

const remove = async (id) => {
    const { data } = await axios.delete(`/api/users/${id}`).then(result => result);
    return data;
}

const getAdUsers = async () => {
    const { data } = await axios.get(`/api/users/all`).then(result => result);
    return data;
};

const myStats = async (values) => {
    const { data } = await axios.get('/api/users/mystats').then(result => result);
    return data;
};

const dashboardStats = async (values) => {
    const { data } = await axios.get('/api/users/dashboardstats').then(result => result);
    return data;
};

const queueStats = async (values) => {
    const { data } = await axios.get('/api/users/queuestats').then(result => result);
    return data;
};

const person = async (id) => {
    const { data } = await axios.get(`/api/users/person/${id}`).then(result => result);
    return data;
}

const setAssignQueues = async (values) => {
    const { data } = await axios.post(`/api/users/queues/assign`, values).then(result => result);
    return data;
}

const setForceAssignQueue = async (values) => {
    const { data } = await axios.post(`/api/users/queues/forceassign`, values).then(result => result);
    return data;
}

const getUserQueuesWithName = async (id) => {
    const { data } = await axios.get(`/api/users/userqueueswithname`).then(result => result);
    return data;
}

const getUsersWithNameAndId = async (id) => {
    const { data } = await axios.get(`/api/users/getallusers`).then(result => result);
    return data;
}

const getPhoto = async (id) => {
    const { data } = await axios.get(`/api/users/photo/${id}`, { responseType: 'blob' }).then(result => result);
    return data;
}

const getADUsersByName = async (name) => {
    const { data } = await axios.get(`/api/users/search/${name}`,).then(result => result);
    return data;
}

const getDashboardUser = async (id) => {
    const { data } = await axios.get(`/api/users/${id}/dashboard`,).then(result => result);
    return data;
}

const updateUserWidgets = async (widgets) => {
    const { data } = await axios.post("/api/users/updatewidgets", widgets).then(result => result);
    return data;
}

const PersistUserFilters = async (filters) => {
    const { data } = await axios.post("/api/users/persistuserfilters", filters).then(result => result);
    return data;
}

//todo apis
const getAllTodos = async () => {
    const { data } = await axios.get('/api/users/todo/list').then(result => result);
    return data;
};

const createTodo = async (values) => {
    const { data } = await axios.post('/api/users/todo', values).then(result => result);
    return data;
};

const updateTodo = async (values) => {
    const { data } = await axios.put('/api/users/todo', values).then(result => result);
    return data;
};

const removeTodo = async (id) => {
    const { data } = await axios.delete(`/api/users/todo/${id}`).then(result => result);
    return data;
};

//attndent console
const acRecentUsers = async () => {
    const { data } = await axios.get('/api/users/attendantconsole/recent').then(result => result);
    return data;
};

const acGroupUsers = async (values) => {
    const { data } = await axios.post(`/api/users/attendantconsole/group/groupusers`, values).then(result => result);
    return data;
};

const acM365groups = async (keyword) => {
    const { data } = await axios.get(`/api/users/attendantconsole/m365groups/${keyword}`).then(result => result);
    return data;
};

const acSelectedGroups = async () => {
    const { data } = await axios.get(`/api/users/attendantconsole/group`).then(result => result);
    return data;
};

const acAddGroup = async (values) => {
    const { data } = await axios.post(`/api/users/attendantconsole/group`, values).then(result => result);
    return data;
};

const acRemoveGroup = async (id) => {
    const { data } = await axios.delete(`/api/users/attendantconsole/group/${id}`).then(result => result);
    return data;
};

const acContactFolders = async (id) => {
    const { data } = await axios.get(`/api/users/attendantconsole/contactfolders`).then(result => result);
    return data;
};

const acContactFoldersInUse = async (id) => {
    const { data } = await axios.get(`/api/users/attendantconsole/contactfoldersinuse`).then(result => result);
    return data;
};

const acAddContactFolder = async (values) => {
    const { data } = await axios.post(`/api/users/attendantconsole/contactfolder`, values).then(result => result);
    return data;
};

const acRemoveContactFolder = async (id) => {
    const { data } = await axios.delete(`/api/users/attendantconsole/contactfolder/${id}`).then(result => result);
    return data;
};

const acContactFolderUsers = async (values) => {
    const { data } = await axios.post(`/api/users/attendantconsole/contactfolder/users`, values).then(result => result);
    return data;
};

const acFavorites = async () => {
    const { data } = await axios.get(`/api/users/attendantconsole/favorites`).then(result => result);
    return data;
};

const acAddFavorites = async (id) => {
    const { data } = await axios.post(`/api/users/attendantconsole/favorites/${id}`).then(result => result);
    return data;
};

const acRemoveFavorites = async (id) => {
    const { data } = await axios.delete(`/api/users/attendantconsole/favorites/${id}`).then(result => result);
    return data;
};

const acSearchAllUsers = async (keyword) => {
    const { data } = await axios.get(`/api/users/attendantconsole/search/${keyword}`).then(result => result);
    return data;
};

const verifyUser = async (id) => {
    const { data } = await axios.post(`/api/users/${id}/verify`).then(result => result);
    return data;
}

const GraphUserDetails = async () => {
    const { data } = await axios.get(`/api/users/graphUserDetails`).then(result => result);
    return data;
}

//Conversation
const GetOptInUsersInQueues = async (values) => {
    const { data } = await axios.post(`/api/users/search-queue-users`, values).then(result => result);
    return data;
};

//Signatures
const GetUserSignatures = async () => {
    const { data } = await axios.get('/api/users/signatures').then(result => result);
    return data;
};

const AddNewSignature = async (values) => {
    const { data } = await axios.post('/api/users/signature/add', values).then(result => result);
    return data;
};

const UpdateSignature = async (values) => {
    const { data } = await axios.post('/api/users/signature/update', values).then(result => result);
    return data;
};

const DeleteSignature = async (id) => {
    const { data } = await axios.post(`/api/users/signature/delete/${id}`).then(result => result);
    return data;
};

const HandleDefaultSignature = async (id, isDefault) => {
    const { data } = await axios.put(`/api/users/signature/${id}/default`, null, {
        params: { isDefault }
    });
    return data;
};

export const UserService = {
    regions,
    login,
    logout,
    getLoginUser,
    getAll,
    getAuthorizedQueues,
    get,
    setAvailability,
    setAvailabilityBySupervisor,
    getLastAvailabilityChange,
    getGraphAccessToken,
    getAdUsers,
    create,
    update,
    enable,
    disable,
    remove,
    myStats,
    dashboardStats,
    queueStats,
    person,
    setAssignQueues,
    setForceAssignQueue,
    getUserQueuesWithName,
    getUsersWithNameAndId,
    getPhoto,
    getADUsersByName,
    getDashboardUser,
    updateUserWidgets,
    PersistUserFilters,
    //todos
    getAllTodos,
    createTodo,
    updateTodo,
    removeTodo,
    //ac
    acRecentUsers,
    acGroupUsers,
    acM365groups,
    acSelectedGroups,
    acAddGroup,
    acRemoveGroup,
    acContactFolders,
    acContactFoldersInUse,
    acAddContactFolder,
    acRemoveContactFolder,
    acContactFolderUsers,
    acFavorites,
    acAddFavorites,
    acRemoveFavorites,
    acSearchAllUsers,
    verifyUser,
    GetOptInUsersInQueues,
    GraphUserDetails,
    GetUserSignatures,
    AddNewSignature,
    UpdateSignature,
    DeleteSignature,
    HandleDefaultSignature
};




